<script setup lang="ts">
  const props = defineProps<{
    item: {
      auth?: string[]
      name: string
      path: string
      icon?: string
      children?: any[]
      disabled?: boolean
      target?: string
    }
    to?: string
    iconOnly?: boolean
    flip?: boolean
    selected?: boolean
  }>()

  const route = useRoute()
  const { t } = useI18n()

  const isActive = (path: string | object) => {
    if (typeof path === 'object') {
      return route.path.startsWith(path.path)
    }
    return route.path.startsWith(path)
  }

  const authStore = useAuthStore()
</script>

<template>
  <li v-if="authStore.isInRoles(item.auth?.roles ?? [])">
    <NuxtLink
      :to="props.item.disabled ? '#' : (props.to ?? props.item.path)"
      :target="props.item.target"
      class="m-2 px-2 py-3 rounded text-base flex gap-2"
      :class="{
        'cursor-pointer': !props.item.disabled,
        'bg-primary text-white': isActive(props.item.path),
        'hover:bg-gray-100 hover:text-black': !isActive(props.item.path),
        'bg-gray-100 text-black': props.selected,
        'font-bold': (item.icon && !to) || isActive(props.item.path),
        ' cursor-not-allowed text-gray-500/60': props.item.disabled,
      }"
    >
      <Icon
        v-if="props.item.icon"
        :name="props.item.icon"
        class="min-w-6 size-6 block"
        :class="{
          'rotate-180': props.flip,
          'text-white': isActive(props.item.path),
          'text-black': !isActive(props.item.path),
        }"
      />
      <span
        class="transition-opacity duration-300 whitespace-nowrap text-ellipsis overflow-hidden ... text-nowrap"
        :class="iconOnly ? 'opacity-0 invisible' : 'opacity-100'"
      >
        {{ t(`nav.menu.${props.item.name}`) }}
      </span>
      <Icon
        v-if="props.item.children"
        name="ic:baseline-chevron-right"
        class="transition-opacity size-6"
        :class="{
          'opacity-0 invisible': iconOnly,
          'opacity-100': !iconOnly,
          'text-white': isActive(props.item.path),
          'text-black': !isActive(props.item.path),
        }"
      />
    </NuxtLink>
  </li>
</template>

<style scoped></style>
