<script setup lang="ts">
  const props = defineProps<{
    item: {
      name: string
      icon?: string
      path: string
      children?: any[]
      isClickable?: boolean
      auth?: string[]
    }
    iconOnly?: boolean
    selected?: boolean
    hasParent?: boolean
  }>()

  const route = useRoute()
  const { t } = useI18n()

  const isActive = (path: string) => {
    return route.path.startsWith(path)
  }

  const emits = defineEmits(['clickArrow'])

  const authStore = useAuthStore()
</script>

<template>
  <li v-if="authStore.isInRoles(item.auth?.roles ?? [])">
    <NuxtLink
      :to="props.item.isClickable ? props.item.path : '#'"
      class="m-2 px-2 py-3 rounded text-base flex gap-2"
      :class="{
        'bg-primary text-white': isActive(props.item.path),
        'hover:bg-gray-100 hover:text-black': !isActive(props.item.path),
        'bg-gray-100 text-black': props.selected,
        'font-bold': !props.hasParent,
        'cursor-pointer': props.item.isClickable,
      }"
    >
      <Icon
        v-if="props.item.icon"
        :name="props.item.icon"
        class="size-6 block min-w-6"
        :class="{
          'text-white': isActive(props.item.path),
          'text-black': !isActive(props.item.path),
        }"
      />
      <span
        class="transition-opacity duration-300 whitespace-nowrap flex-grow text-ellipsis overflow-hidden ... text-nowrap"
        :class="iconOnly ? 'opacity-0 invisible' : 'opacity-100'"
      >
        {{ t(`nav.menu.${props.item.name}`) }}
      </span>
      <Icon
        v-if="props.item.children"
        @click="emits('clickArrow')"
        name="ic:baseline-chevron-right"
        class="transition-opacity size-6"
        :class="{
          'opacity-0 invisible': iconOnly,
          'opacity-100': !iconOnly,
          'text-white': isActive(props.item.path),
          'text-black': !isActive(props.item.path),
        }"
      />
    </NuxtLink>
  </li>
</template>

<style scoped></style>
