<script setup lang="ts">
  import BasicNavItem from '../basic/BasicNavItem.vue'
  import BasicNestedNavItem from '../basic/BasicNestedNavItem.vue'
  import { get } from 'lodash'

  const { t } = useI18n()

  const props = defineProps<{
    routes: {
      name: string
      path: string
      icon?: string
      disabled?: boolean
      auth?: {
        roles: string[]
      }
      children?: {
        name: string
        path: string
        disabled?: boolean
        auth?: {
          roles: string[]
        }
        children?: {
          name: string
          path: string
          disabled?: boolean
          auth?: {
            roles: string[]
          }
        }[]
      }[]
    }[]
  }>()

  const navigationStore = useNavigationStore()

  const selectedIndex = ref([])
  const manualShowClicked = useLocalStorage('lockNavigation2', () => false)
  const router = useRouter()

  const selectedIndexPath = computed(() =>
    selectedIndex.value.flatMap((value, index, array) => {
      return index < array.length - 1 ? [value, 'children'] : [value]
    }),
  )
  const selectedRoutes = computed(() => selectedParent.value.children)
  const selectedParent = computed(() => get(props.routes, selectedIndexPath.value))

  watch(props, () => {
    selectedIndex.value = []
  })

  const { width } = useWindowSize()

  const hover = ref(false)

  const svgVisible = ref(false)

  const authStore = useAuthStore()

  const toggleSvg = () => {
    svgVisible.value = true

    setTimeout(() => {
      svgVisible.value = false
    }, 500)
  }

  const navigateToHelpdesk = () => {
    const helpdeskLink = t('nav.menu.helpdeskLink')
    navigateTo(helpdeskLink, {
      open: {
        target: '_blank',
      },
    })
  }

  watch(
    () => navigationStore.$state.lockNavigation,
    () => {
      toggleSvg()
    },
  )
</script>

<template>
  <nav
    class="duration-500 border-r flex flex-col items-stretch overflow-y-auto bg-white"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="{
      'min-w-[345px] max-w-[345px]': navigationStore.lockNavigation || hover,
      'min-w-[57px] max-w-[57px]': !navigationStore.lockNavigation && !hover,
      'min-w-[345px] max-w-[345px] absolute z-30 bg-white bottom-0 top-20 shadow-2xl':
        (width < 1280 && navigationStore.lockNavigation) || (width < 1280 && hover),
      'min-w-[57px] max-w-[57px] absolute z-30 bg-white bottom-0 top-20':
        width < 1280 && !navigationStore.lockNavigation && !hover,
    }"
  >
    <div
      class="px-3 py-2 border-b h-12 transition-all"
      :class="navigationStore.lockNavigation ? 'flex justify-between' : ''"
    >
      <button
        driver-id="navigation-lock-button"
        class="rounded-full transition-all border hover:bg-gray-500/10 bg-center bg-[length:20px_20px] w-8 h-8 bg-no-repeat duration-500"
        :class="{
          'ml-[calc(345px-32px-24px)] bg-[url(/icons/lock_24px_outlined.svg)]':
            navigationStore.lockNavigation,
          'ml-0 bg-[url(/icons/lock_open_24px_outlined.svg)]': !navigationStore.lockNavigation,
        }"
        @click="
          () => {
            navigationStore.toggleLockNavigation()
            manualShowClicked = true
          }
        "
      />
    </div>
    <div class="flex flex-row flex-grow">
      <ul
        id="root-nav"
        class="transition-all duration-100"
        :class="selectedIndex.length > 0 ? 'w-[57px] border-r' : 'w-full'"
      >
        <template v-for="(route, index) in props.routes" :key="index">
          <BasicNavItem
            :id="route.id"
            v-if="!route.children && route.path"
            @click="selectedIndex = []"
            :item="route"
            :icon-only="(!navigationStore.lockNavigation && !hover) || selectedIndex.length > 0"
          />
          <BasicNestedNavItem
            :id="route.id"
            @click="
              () => {
                selectedIndex = [index]
              }
            "
            :icon-only="(!navigationStore.lockNavigation && !hover) || selectedIndex.length > 0"
            v-else-if="route.children"
            :item="route"
            :selected="selectedIndex[0] == index"
          />
          <BasicNavItem
            :id="route.id"
            v-else
            :item="route"
            :icon-only="(!navigationStore.lockNavigation && !hover) || selectedIndex.length > 0"
          />
        </template>
      </ul>
      <ul
        id="nested"
        v-if="selectedIndex.length > 0"
        class="w-full overflow-auto max-h-[calc(100vh-8rem)]"
      >
        <BasicNavItem
          :item="{
            name: selectedIndex.length > 1 ? selectedParent?.name : 'mainMenu',
            icon: 'ic:baseline-chevron-right',
          }"
          flip
          @click="selectedIndex.pop()"
          v-show="navigationStore.lockNavigation || hover"
        />
        <template v-for="(route, index) in selectedRoutes" :key="index">
          <BasicNavItem
            :item="route"
            v-if="!route.children && route.path"
            v-show="navigationStore.lockNavigation || hover"
          />
          <BasicNestedNavItem
            has-parent
            @click="selectedIndex.push(index)"
            :item="route"
            v-else-if="route.children"
            v-show="navigationStore.lockNavigation || hover"
          />
        </template>
      </ul>
    </div>
    <div class="block border-t py-4 px-3">
      <!--      todo: help icon-->
      <div
        driver-id="help-button"
        class="flex justify-center items-center h-8 bg-affirmative rounded-full cursor-pointer"
        :class="{}"
        @click="navigateToHelpdesk"
      >
        <img src="/icons/help-24px-white.svg" class="w-5 h-5" />
        <p
          v-if="navigationStore.lockNavigation || hover"
          class="opacity-100 transition-opacity text-white ml-2"
        >
          {{ t('nav.menu.helpdesk') }}
        </p>
      </div>
    </div>
  </nav>

  <ClientOnly>
    <Teleport to="body">
      <div
        class="fixed inset-0 flex flex-col items-center justify-center pointer-events-none transition-all ease-in-out duration-500 z-[999] bg-white/30"
        :class="{
          'opacity-0': !svgVisible,
          'opacity-100': svgVisible,
        }"
      >
        <img
          :src="
            navigationStore.lockNavigation
              ? `/icons/lock_24px_outlined.svg`
              : '/icons/lock_open_24px_outlined.svg'
          "
          class="w-20 h-20"
        />
        <p class="text-primary-light">
          {{ navigationStore.lockNavigation ? 'Locked' : 'Unlocked' }} menu position
        </p>
      </div>
    </Teleport>
  </ClientOnly>
</template>
