<script lang="ts" setup>
  const props = defineProps<{
    environment: keyof typeof environments
  }>()

  const { t } = useI18n()

  const route = useRoute()

  const environments = {
    portal: { color: 'text-primary', title: '' },
    management: { color: 'text-affirmative', title: 'Management' },
    academy: { color: 'text-critical', title: 'Academy' },
    cms: { color: 'text-black', title: 'CMS' },
    settings: { color: 'text-black', title: '' },
  }
  const openSupportPlatform = () => {
    window.open('https://www.gmpplus.org/support-platform/', '_support')
  }

  const selectedEnvironment = computed(() => environments[props.environment])
</script>

<template>
  <header class="h-20 px-12 border-b border-[#e3e3e3] flex items-center shrink-0 bg-white">
    <div id="logo" class="flex-shrink-0">
      <NuxtLink to="/">
        <img alt="logo" src="/icons/Logo.svg" />
      </NuxtLink>
    </div>
    <div id="env" class="px-12 w-full">
      <h3 :class="selectedEnvironment.color" class="text-2xl font-bold">
        {{ selectedEnvironment.title }}
      </h3>
    </div>
    <div class="flex flex-row mr-4 gap-2">
      <BasicBtn
        class="flex items-center justify-center gap-1 px-7 py-2s"
        size="regular"
        type="submit"
        color="affirmative"
        @click="openSupportPlatform()"
      >
        <img :alt="t('nav.menu.support')" src="/icons/help-24px-white.svg" class="w-5 h-5" />
        {{ t('nav.menu.support') }}
      </BasicBtn>
    </div>
    <div class="flex flex-row justify-end mr-4 gap-2">
      <JiraFeedback />
    </div>
    <div class="ml-auto flex-shrink-0">
      <TheProfileMenu id="profile-menu" :environment="environment" />
    </div>
  </header>
</template>

<style scoped></style>
