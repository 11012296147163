<script setup lang="ts">
  const props = defineProps<{
    label: string
    icon?: string
    active?: boolean
    radio?: boolean
  }>()

  const emit = defineEmits<{
    (e: 'click'): void
  }>()

  const { t } = useI18n()
</script>

<template>
  <button
    type="button"
    @click.stop="emit('click')"
    class="p-4 text-left flex flex-row items-center hover:bg-gray-100 transition-all duration-300 font-bold w-full disabled:text-primary disabled:hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-focused duration-250"
  >
    <span
      v-if="active && radio"
      class="mr-4 min-w-6 w-6 h-6 border-gray-200 border-[6px] bg-primary rounded-full"
    >
    </span>
    <span
      v-else-if="!active && radio"
      class="mr-4 min-w-6 w-6 h-6 bg-gray-200 border-[6px] rounded-full"
    >
    </span>
    <slot name="prefix" />
    <span v-if="icon">
      <Icon v-if="icon.startsWith('ic:')" :name="icon" class="mr-2 size-6" />
      <img v-else :src="`/icons/${props.icon}.svg`" class="w-6 h-6 mr-2" alt="" />
    </span>
    <span>
      {{ t(props.label) }}
    </span>
    <slot />
  </button>
</template>

<style scoped></style>
